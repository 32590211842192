import * as Sentry from "@sentry/remix";
import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";
import { startTransition, StrictMode, useEffect } from "react";
import { hydrateRoot } from "react-dom/client";
import { initAnalytics } from "./analytics.client";

Sentry.init({
  dsn: "https://b679e51556503a3645d52e24282742af@o4507684940873728.ingest.de.sentry.io/4507684944740432",
  tracesSampleRate: 1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,

  integrations: [
    Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
    Sentry.replayIntegration(),
  ],
});

function InitAnalytics() {
  useEffect(() => {
    // initAnalytics();
  }, []);
  return null;
}

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
      <InitAnalytics />
    </StrictMode>
  );
});
